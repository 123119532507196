interface IFooterEngine {
  phoneNumber: string
}

function FooterEngine(props: IFooterEngine): React.ReactElement {
  const { phoneNumber } = props

  return (
    <section>
      <div className="overflow-hidden pt-9 mx-auto max-w-screen-xl text-center footer-engage-wrap">
        <div className="text-center ways-to-shop w-[calc((100%*0.1118)+316px)]  mr-[calc((100%*0.1842)-160px)]  float-left px-[10px] mb-[30px]">
          <h6 className="footer-heading">Other Ways to Shop</h6>
          <ul className="table w-full ways-to-shop-list">
            <li className="financing">
              <a
                href="/pages/finance--Special-Financing-Offers"
                target="_self"
                className="text-[transparent]"
              >
                financing
              </a>
              <p>Financing</p>
            </li>
            <li className="apps">
              <a
                href="https://smart.link/5b5900bcdbd02"
                target="_self"
                className="text-[transparent]"
              >
                app
              </a>
              <p>App</p>
            </li>
            <li className="hidden catalog">
              <a href="/" target="_self" className="text-[transparent]">
                catalog
              </a>
              <p>Catalog</p>
            </li>
            <li className="gift-cards">
              <a
                href="/Gift-Card"
                target="_self"
                className="text-[transparent]"
              >
                gift cards
              </a>
              <p>Gift Cards</p>
            </li>
            <li className="phone">
              <a href={`tel:${phoneNumber}`} className="text-[transparent]">
                phone number
              </a>
              <p>{phoneNumber ? phoneNumber : '866-498-7882'}</p>
            </li>
          </ul>
        </div>

        <div className="text-center connect-with-us w-[calc((100%*0.046)+291.29px)] float-left px-[10px] mb-[30px]">
          <h6 className="footer-heading">Connect With Us</h6>
          <ul className="table w-full social-media-list">
            <li className="facebook">
              <a
                href="https://www.facebook.com/GuitarCenter"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[transparent]"
              >
                facebook
              </a>
              <p>
                Facebook
                <span className="screen-reader-only">Opens in new window</span>
              </p>
            </li>
            <li className="twitter">
              <a
                href="https://twitter.com/guitarcenter"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[transparent]"
              >
                twitter
              </a>
              <p>
                Twitter
                <span className="screen-reader-only">Opens in new window</span>
              </p>
            </li>
            <li className="youtube">
              <a
                href="https://www.youtube.com/guitarcenter"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[transparent]"
              >
                YouTube
              </a>
              <p>
                YouTube
                <span className="screen-reader-only">Opens in new window</span>
              </p>
            </li>
            <li className="instagram">
              <a
                href="https://instagram.com/guitarcenter#"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[transparent]"
              >
                Instagram
              </a>
              <p>
                Instagram
                <span className="screen-reader-only">Opens in new window</span>
              </p>
            </li>
          </ul>
        </div>
      </div>

      <style jsx>{`
        .footer-engage-wrap {
          & ul li {
            position: relative;
            display: table-cell;
            height: 70px;
            text-align: center;
            cursor: pointer;
            &:hover p {
              color: #cd2418;
            }
            & a {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }

            &::before {
              display: inline-block;
              width: 66px;
              height: 43px;
              background: url(/assets/images/common/sprite-gc-global.png)
                no-repeat -348px -122px;
              content: '';
            }

            & p {
              display: block;
              width: 100%;
              color: #000;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              white-space: nowrap;
              @media (max-width: 767px) {
                max-width: 70px;
                font-size: 12px;
                white-space: normal;
              }
            }
          }

          .ways-to-shop-list {
            & li {
              width: 62px;
              font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
              &.hidden {
                display: none;
              }
            }
            & li.financing::before {
              background-position: -348px -122px;
            }

            & li.financing:hover::before {
              background-position: -348px -171px;
            }

            & li.apps::before {
              width: 32px;
              background-position: -437px -122px;
            }

            & li.apps:hover::before {
              background-position: -437px -171px;
            }

            & li.catalog::before {
              width: 51px;
              background-position: -492px -122px;
            }

            & li.catalog:hover::before {
              background-position: -492px -171px;
            }

            & li.gift-cards::before {
              width: 68px;
              background-position: -565px -122px;
            }

            & li.gift-cards:hover::before {
              background-position: -565px -171px;
            }

            & li.phone::before {
              width: 41px;
              background-position: -646px -122px;
            }

            & li.phone:hover::before {
              background-position: -646px -171px;
            }
          }

          .social-media-list {
            & li {
              width: 20%;
              font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
            }
            & li::before {
              width: 44px !important;
            }

            & li.facebook::before {
              background-position: -248px -217px;
            }

            & li.facebook:hover::before {
              background-position: -298px -217px;
            }

            & li.twitter::before {
              background-position: -352px -217px;
            }

            & li.twitter:hover::before {
              background-position: -409px -217px;
            }

            & li.youtube::before {
              background-position: -463px -217px;
            }

            & li.youtube:hover::before {
              background-position: -517px -217px;
            }

            & li.instagram::before {
              background-position: -573px -217px;
            }

            & li.instagram:hover::before {
              background-position: -630px -217px;
            }
          }
        }
      `}</style>
    </section>
  )
}

export default FooterEngine
